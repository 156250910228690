import React, { useCallback, useMemo, useRef } from 'react'
import { bool, func, oneOf, string } from 'prop-types'

import { ErrorMessage } from '@hookform/error-message'
import { useTriggerEvents } from 'hooks/useTriggerEvents'
import debounce from 'lodash/debounce'
import { useFormContext } from 'react-hook-form'
import { triggerEvent as triggerEventLegacy } from 'utils/analytics'
import { getInputConfig } from 'utils/getInputConfig'

import Fields from 'components/FormElements'

import { Description } from './HubspotField.style'

const propTypes = {
  'data-testid': string,
  description: string,
  dispatchEvents: bool,
  enabled: bool,
  fieldType: oneOf([
    'booleancheckbox',
    'checkbox',
    'number',
    'radio',
    'select',
    'text',
    'textarea',
  ]),
  formName: string,
  hidden: bool,
  id: string,
  isGreenStone: bool,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  register: func,
  required: bool,
  type: string,
}

const defaultProps = {
  enabled: true,
  'data-testid': 'hubspot-field',
  onBlur: () => null,
  onChange: () => null,
  onFocus: () => null,
}

// eslint-disable-next-line max-lines-per-function
const HubspotField = ({
  'data-testid': dataTestid,
  description,
  dispatchEvents,
  enabled,
  fieldType,
  formName,
  hidden,
  id,
  isGreenStone,
  name,
  onBlur,
  onChange,
  onFocus,
  required,
  ...others
}) => {
  const { triggerEvent } = useTriggerEvents()
  const { mask, message, pattern, type: inputType } = getInputConfig(name)
  const { errors, register } = useFormContext() || {
    errors: {},
    register: () => {},
  }
  const optionalRef = useRef(null)
  const errorsArray = errors?.[name]

  const fieldRegister = ref =>
    register
      ? register(ref, {
          required: {
            value: required,
            message: 'Campo obrigatório',
          },
          pattern: {
            value: pattern,
            message,
          },
        })
      : optionalRef

  const dispatchEvent = useCallback(
    elementEvent => {
      if (!formName || !name) return

      const analyticEvent = {
        eventCategory: 'Interactions',
        eventAction: `Form: ${formName} | Field: ${name} | ${elementEvent}`,
      }

      if (isGreenStone && dispatchEvents) {
        triggerEvent({
          label: `${formName} | ${name}`,
          pageInteraction: true,
        })
      } else {
        triggerEventLegacy(analyticEvent)
      }
    },
    [dispatchEvents, formName, isGreenStone, name, triggerEvent],
  )

  const debounceEventOnChange = debounce(() => dispatchEvent('onChange'), 1000)

  const handleBlur = useCallback(
    e => {
      if (dispatchEvents) {
        if (errorsArray)
          triggerEvent({
            label: `${formName} | error | ${name}`,
            pageInteraction: true,
          })
      } else {
        dispatchEvent('onBlur')
      }

      return onBlur?.(e)
    },
    [
      dispatchEvent,
      dispatchEvents,
      errorsArray,
      formName,
      name,
      onBlur,
      triggerEvent,
    ],
  )

  const handleChange = useCallback(
    e => {
      debounceEventOnChange()

      return onChange?.(e)
    },
    [debounceEventOnChange, onChange],
  )

  const handleFocus = e => {
    if (dispatchEvents) {
      triggerEvent({
        label: `${formName} | ${name}`,
        pageInteraction: true,
      })
    } else {
      dispatchEvent('onFocus')
    }

    return onFocus?.(e)
  }

  const inputVariant = useMemo(() => {
    return errorsArray ? 'danger' : 'default'
  }, [errorsArray])

  return (
    <div className="hubspot-field" hidden={hidden} data-testid={dataTestid}>
      <Fields
        data-testid={`${dataTestid}-field`}
        disabled={!enabled}
        error={errorsArray && errorsArray.message}
        fieldType={fieldType}
        id={id}
        inputType={inputType}
        isGreenStone={isGreenStone}
        mask={mask}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        variant={inputVariant}
        ref={fieldRegister}
        {...others}
      />

      {description && (
        <Description
          data-testid={`${dataTestid}-description-field`}
          variant="supportForm"
        >
          {description}
        </Description>
      )}

      {errors && !isGreenStone && (
        <ErrorMessage
          errors={errors}
          name={name}
          as={
            <Description
              role="alert"
              data-testid={`${name}-error-message`}
              color={inputVariant}
              variant="supportForm"
            />
          }
        />
      )}
    </div>
  )
}

HubspotField.displayName = 'HubspotField'
HubspotField.propTypes = propTypes
HubspotField.defaultProps = defaultProps

export default HubspotField
