import React from 'react'
import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: string,
  color: string,
}

const defaultProps = {
  color: 'neutral.90',
}

const Title = ({ ...others }) => {
  return (
    <Typography
      color="neutral.90"
      variant={{ _: 'headingSmLight', '5xl': 'headingMdLight' }}
      {...others}
    />
  )
}

Title.propTypes = propTypes
Title.defaultProps = defaultProps

export default Title
