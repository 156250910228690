import React from 'react'
import { node } from 'prop-types'

import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

import { webCardTitleWithRightIcon } from './webCard.style'

const propTypes = {
  children: node,
}

const WebCardTitleProductStyled = styled(Typography)`
  text-decoration: none;
  ${webCardTitleWithRightIcon}
`

const WebCardTitleProduct = ({ children, ...other }) => (
  <WebCardTitleProductStyled variant="h6" {...other}>
    {children}
  </WebCardTitleProductStyled>
)

WebCardTitleProduct.propTypes = propTypes

export { WebCardTitleProduct }
