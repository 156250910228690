import React from 'react'
import { bool, node } from 'prop-types'

import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

const propTypes = {
  children: node,
  fullHeight: bool,
}

const WebCardParagraphStyled = styled(Typography)`
  ${({ fullHeight }) => fullHeight && `height: 100%;`}
`

const WebCardParagraph = ({ children, fullHeight, ...others }) => (
  <WebCardParagraphStyled
    {...others}
    variant="paragraph"
    fullHeight={fullHeight}
  >
    {children}
  </WebCardParagraphStyled>
)

WebCardParagraph.propTypes = propTypes

export { WebCardParagraph }
