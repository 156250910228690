import { styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  white-space: nowrap;
`

export const Indicator = styled.div`
  align-items: center;
  border-radius: 50%;
  color: ${selectTheme('colors.neutral.0')};
  display: flex;
  font-size: 14px;
  height: 24px;
  justify-content: center;
  min-height: 24px;
  min-width: 24px;
  text-align: center;
  width: 24px;

  &.active {
    background: ${selectTheme('colors.primary.40')};
    color: ${selectTheme('colors.neutral.90')};
  }

  &.disabled {
    background: ${selectTheme('colors.neutral.40')};
  }

  &.checked {
    background: ${selectTheme('colors.neutral.90')};
  }
`

export const StepName = styled.div`
  ${getTypography('bodySmMedium')}

  &.disabled {
    // Used hard-coded hex because we don't have token for colors.primary.15
    color: #a5aea7;
  }

  &.disabled,
  &.checked {
    display: none;
  }

  ${media.up('5xl')} {
    &.disabled,
    &.checked {
      display: block;
    }
  }
`

export const CheckIcon = styled.img`
  height: 6.42px;
  width: 9.33px;
`

export const Separator = styled.div`
  background: #dbe1dd;
  height: 1px;
  width: 24px;
`
