import React from 'react'
import { string } from 'prop-types'

import { Container } from './styles'

export const propTypes = {
  iconSrc: string,
}

const defaultProps = {
  iconSrc: '',
}

const Icon = ({ iconSrc, ...others }) => {
  return <Container src={iconSrc} {...others} />
}

Icon.propTypes = propTypes
Icon.defaultProps = defaultProps
export default Icon
