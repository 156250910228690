import { CardContent } from '@creditas/card'
import { styled } from '@creditas/stylitas'

import { webCardContent, webCardTitleWithRightIcon } from './webCard.style'

const WebCardContent = styled(CardContent)`
  ${webCardContent}
  ${webCardTitleWithRightIcon}
`

export { WebCardContent }
