import { array, number, string } from 'prop-types'

import { Wizard as CreditasWizard } from '@creditas/wizard'

import { WizardContainer, WizardInfo } from '../../FormStepper.style'

const propTypes = {
  currentStep: number,
  currentStepName: string,
  steps: array,
}

const Wizard = ({ currentStep, currentStepName, steps }) => {
  return (
    <WizardContainer>
      <CreditasWizard
        className="wizard"
        steps={steps}
        currentStep={currentStep}
        data-testid="wizard"
      />

      <WizardInfo variant="support">{currentStepName}</WizardInfo>
    </WizardContainer>
  )
}

Wizard.propTypes = propTypes

export default Wizard
