import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

const Typography = styled(RichText)`
  margin-bottom: ${selectTheme('spacingBottom.10xl')};
  ${media.up('5xl')} {
    margin-bottom: ${selectTheme('spacingBottom.12xl')};
  }
`

export { Typography }
