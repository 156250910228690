import React from 'react'
import { bool, func, shape, string } from 'prop-types'

import errorIcon from './assets/error_icon.svg'
import successIcon from './assets/success_icon.svg'
import ErrorMessage from './Error'
import SuccessMessage from './Success'

const VARIANT_ELEMENTS = {
  success: SuccessMessage,
  error: ErrorMessage,
}

const ICON_TYPES = {
  success: successIcon,
  error: errorIcon,
}

const propTypes = {
  errorProps: shape({
    errorMessage: string,
    errorTitle: string,
    retryCtaAction: func,
    retryCtaLabel: string,
  }),
  formName: string,
  isSuccess: bool,
  successProps: shape({
    successMessage: string,
    successTitle: string,
    successCtaAction: func,
    successCtaLabel: string,
  }),
}

const PostSubmitMessage = ({
  errorProps,
  isSuccess,
  successProps,
  ...others
}) => {
  const Element = VARIANT_ELEMENTS[isSuccess ? 'success' : 'error']
  const icon = ICON_TYPES[isSuccess ? 'success' : 'error']

  const defaultProps = {
    icon,
    ...others,
  }
  const elementProps = isSuccess ? successProps : errorProps

  return <Element {...{ ...elementProps, ...defaultProps }} />
}

PostSubmitMessage.propTypes = propTypes

export default PostSubmitMessage
