import React from 'react'
import { func, string } from 'prop-types'

import ButtonCta from '../components/ButtonCta'
import Description from '../components/Description'
import Icon from '../components/IconWithShape'
import InfoContainer from '../components/InfoContainer'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const propTypes = {
  icon: string,
  successCtaAction: func,
  successCtaLabel: string,
  successMessage: string,
  successTitle: string,
}

const defaultProps = {
  successTitle: 'Tudo certo!</br> Já recebemos seus dados.',
  successMessage: 'Obrigado por preencher nosso formulário!',
  successCtaLabel: 'Ok, obrigado',
}

const SuccessMessage = ({
  icon,
  successCtaAction,
  successCtaLabel: customSuccessCtaLabel,
  successMessage: customSuccessMessage,
  successTitle: customSuccessTitle,
}) => {
  const successMessage = customSuccessMessage ?? defaultProps.successMessage
  const successTitle = customSuccessTitle ?? defaultProps.successTitle
  const successCtaLabel = customSuccessCtaLabel ?? defaultProps.successCtaLabel

  const hasButtonCta = successCtaLabel && successCtaAction
  return (
    <Wrapper>
      <Icon iconSrc={icon} />
      <InfoContainer>
        <Title>{successTitle}</Title>
        <Description>{successMessage}</Description>
        {hasButtonCta && (
          <ButtonCta label={successCtaLabel} onClick={successCtaAction} />
        )}
      </InfoContainer>
    </Wrapper>
  )
}

SuccessMessage.propTypes = propTypes
export default SuccessMessage
