import React from 'react'
import { bool, node, string } from 'prop-types'

const propTypes = {
  children: node,
  show: bool,
  title: string,
}
const FormStep = ({ children, show, title }) => (
  <div
    data-testid={`form-step-${title}`}
    style={{
      display: show ? 'block' : 'none',
    }}
  >
    {children}
  </div>
)

FormStep.propTypes = propTypes

export default FormStep
