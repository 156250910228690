import { useCallback, useLayoutEffect, useState } from 'react'

export default function useTriggerOnScroll(ref, onTrigger, triggerRatio = 1.5) {
  const [isTriggered, setTriggered] = useState(false)
  const [firstCheck, setFirstCheck] = useState(false)

  let dynamicCheck

  const handleTrigger = useCallback(
    (position = { top: 0, height: 0 }) => {
      if (position.top < position.height / triggerRatio && !isTriggered) {
        setTriggered(true)
        onTrigger()
      }
    },
    [isTriggered, onTrigger, triggerRatio],
  )

  // Check if the component already appear in the user's viewport
  if (!firstCheck && ref) {
    dynamicCheck = setTimeout(() => {
      const position = ref.current?.getBoundingClientRect()
      setFirstCheck(true)
      handleTrigger(position)
    }, 120)
  }

  // Listen if the scroll changes
  useLayoutEffect(() => {
    if (ref) {
      const onScroll = () => {
        const position = ref.current?.getBoundingClientRect()
        handleTrigger(position)
      }

      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    }
    return clearTimeout(dynamicCheck)
  }, [dynamicCheck, handleTrigger, ref])
}
