import React, { useCallback } from 'react'
import { bool, elementType, func, node, oneOf, string } from 'prop-types'

import { useSimulator } from '@creditas-lab/simulator'
import { scrollTo } from 'ui/utils/scrollTo'

import { Wrapper } from './styles'

export const propTypes = {
  accessibilityDescription: string,
  children: node,
  'data-testid': string,
  element: elementType,
  isButtonElement: bool,
  onClick: func,
  openInNewTab: bool,
  type: oneOf(['link', 'scrollTo', 'simulator']),
  url: string,
}

const defaultProps = {
  accessibilityDescription: '',
  'data-testid': 'component-cta',
  type: 'link',
  element: 'a',
}

const Cta = props => {
  const {
    accessibilityDescription,
    children,
    'data-testid': dataTestid,
    element,
    onClick,
    openInNewTab,
    type = defaultProps.type,
    url,
    isButtonElement,
    ...others
  } = props

  const { openSimulator } = useSimulator()

  const Element = element || Wrapper

  const isAnchorTag = isButtonElement
    ? false
    : type === 'link' || type === 'scrollTo'
  const SPECIFIC_PROPS = isAnchorTag
    ? {
        href: url,
        target: openInNewTab ? '_blank' : '_self',
        rel: openInNewTab ? 'noopener' : null,
        as: 'a',
      }
    : {}

  const handleClick = useCallback(() => {
    if (onClick) onClick()

    if (type === 'simulator') {
      return openSimulator()
    }

    if (type === 'scrollTo') {
      return scrollTo(url)
    }

    return false
  }, [type, onClick, openSimulator, url])

  return (
    <Element
      aria-label={accessibilityDescription}
      data-testid={dataTestid}
      onClick={handleClick}
      title={accessibilityDescription}
      {...others}
      {...SPECIFIC_PROPS}
    >
      {children}
    </Element>
  )
}

Cta.propTypes = propTypes
Cta.defaultProps = defaultProps
Cta.typeName = 'ContentfulComponentCta'

export { Cta }
export default Cta
