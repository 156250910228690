import React from 'react'
import { node, object, oneOf, string } from 'prop-types'

import HubspotForm from 'components/HubspotForm/HubspotForm'

import { Description } from './components/Description'
import { Form } from './components/Form'
import { Image } from './components/Image'
import { Info } from './components/Info'
import { PreTitle } from './components/PreTitle'
import { Title } from './components/Title'
import Wrapper from './components/Wrapper'

const propTypes = {
  children: node,
  description: string,
  form: object,
  horizontalAlignment: string,
  id: string,
  imageDesktop: object,
  imageMobile: object,
  preTitle: string,
  preTitleHeadingTag: oneOf(['h1', 'h2']),
  title: string,
  titleHeadingTag: oneOf(['h1', 'h2']),
}

const HeroFormWithImage = ({
  children,
  description,
  form,
  horizontalAlignment,
  id,
  imageDesktop,
  imageMobile,
  preTitle,
  preTitleHeadingTag,
  title,
  titleHeadingTag,
  ...others
}) => {
  const preTitleAs = preTitleHeadingTag || 'h1'
  const titleAs = preTitle ? titleHeadingTag || 'h2' : 'h1'

  return (
    <Wrapper
      id={id}
      data-testid="heroFormSection"
      horizontalAlignment={horizontalAlignment}
      {...others}
    >
      {imageDesktop && (
        <Image
          imageDesktop={imageDesktop}
          imageMobile={imageMobile ? imageMobile : imageDesktop}
        />
      )}
      <Info>
        {preTitle && (
          <PreTitle
            variant="exceptionMdBold"
            as={preTitleAs}
            data-testid="preTitle"
            color="neutral.60"
          >
            {preTitle}
          </PreTitle>
        )}
        {title && (
          <Title
            variant="headingXlLight"
            as={titleAs}
            data-testid="title"
            color="neutral.90"
          >
            {title}
          </Title>
        )}
        {description && (
          <Description
            variant="bodyXlLight"
            data-testid="description"
            color="neutral.80"
          >
            {description}
          </Description>
        )}
        {form && (
          <Form>
            <HubspotForm {...form} componentId={id} />
          </Form>
        )}
        {children}
      </Info>
    </Wrapper>
  )
}

HeroFormWithImage.propTypes = propTypes

export { HeroFormWithImage }
export default HeroFormWithImage
