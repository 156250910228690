import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

export const Form = styled.div`
  background-color: ${selectTheme('colors.neutral.0')};
  border-radius: 20px;
  border: 1px solid ${selectTheme('colors.neutral.10')};
  box-shadow: ${selectTheme('shadow.xs')};
  color: ${selectTheme('colors.neutral.80')};
  margin-bottom: 64px;
  min-height: 305px;
  padding: 24px;
  max-width: 480px;

  ${media.up('5xl')} {
    padding: 48px;
  }
`
