import React from 'react'
import { object } from 'prop-types'

import { ImageElement } from './styles'

const propTypes = {
  imageDesktop: object,
  imageMobile: object,
}

export const Image = ({ imageDesktop, imageMobile }) => {
  const images = [
    {
      ...imageDesktop?.fluid,
      description: imageDesktop?.description,
      media: '(min-width: 960px)',
      sizes: '(max-width: 1920px)',
    },
    {
      ...imageMobile?.fluid,
      description: imageMobile?.description,
      sizes: '(max-width: 767px)',
    },
  ]

  return <ImageElement objectFit="contain" artDirection={images} />
}
Image.propTypes = propTypes
