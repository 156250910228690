import { css, styled } from '@creditas-ui/system'

import { LazyImage } from 'components/LazyImage'

export const SIZE_VARIATIONS = {
  'x-small': 8,
  small: 16,
  medium: 24,
  large: 32,
  'x-large': 40,
  '2x-large': 80,
}

export const IconContainer = styled.div(
  ({ size }) => css`
    position: relative;

    width: ${size ? `${SIZE_VARIATIONS[size]}px` : '100%'};
    height: ${size ? `${SIZE_VARIATIONS[size]}px` : '100%'};
  `,
)

export const Icon = styled(LazyImage)`
  object-fit: contain;
  width: 100%;
  height: 100%;
`
