import { Button } from '@creditas/button'
import { styled } from '@creditas-ui/system'

export const ButtonLegacy = styled(Button)`
  width: 100%;

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  svg {
    width: 22px;
  }
`
