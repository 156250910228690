import { string } from 'prop-types'

import { StyledLegend } from './styles'

const propTypes = {
  as: string,
  children: string,
}

const defaultProps = {
  as: 'legend',
}

export const Legend = ({ as, children, ...others }) => {
  if (!children) return null

  return (
    <StyledLegend as={as} {...others}>
      {children}
    </StyledLegend>
  )
}

Legend.propTypes = propTypes
Legend.defaultProps = defaultProps
