import React from 'react'
import { bool, func, oneOf, string } from 'prop-types'

import { InputBoxed } from '@creditas-ui/input'
import NumberFormat from 'react-number-format'

import { Container } from './styles'

const MASK_OPTIONS = {
  cpf: {
    format: '###.###.###-##',
  },
  cnpj: {
    format: '##.###.###/####-##',
  },
  phone: {
    format: '(##) ####-#####',
  },
  currency: {
    thousandSeparator: '.',
    decimalScale: 2,
    prefix: 'R$ ',
  },
  cep: {
    format: '#####-###',
  },
}

const propTypes = {
  'data-testid': string,
  defaultValue: string,
  disabled: bool,
  error: string,
  inputType: oneOf(['text', 'email', 'number', 'search', 'tel', 'password']),
  label: string,
  mask: oneOf(['cpf', 'cnpj', 'phone', 'currency']),
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  required: bool,
}

const defaultProps = {
  inputType: 'text',
}

const InputField = React.forwardRef((props, ref) => {
  const {
    'data-testid': dataTestId,
    defaultValue,
    disabled,
    error,
    inputType,
    label,
    mask,
    name,
    onBlur,
    onChange,
    onFocus,
    required,
    ...others
  } = props

  const maskProps = {
    mask: '',
    customInput: InputBoxed,
    isNumericString: true,
    getInputRef: ref,
    decimalSeparator: ',',
    ...MASK_OPTIONS[mask],
  }

  const inputProps = {
    name,
    label,
    disabled,
    onBlur,
    onChange,
    onFocus,
    required,
    error,
    ref,
    'data-testid': dataTestId,
    autocomplete: 'off',
    defaultValue,
    ...others,
    type: inputType,
  }

  const isMasked = mask || inputType === 'number'

  return (
    <Container>
      {isMasked ? (
        <NumberFormat {...maskProps} {...inputProps} type="tel" ref={ref} />
      ) : (
        <InputBoxed ref={ref} {...inputProps} />
      )}
    </Container>
  )
})

InputField.propTypes = propTypes
InputField.defaultProps = defaultProps
InputField.displayName = 'InputField'

export default InputField
