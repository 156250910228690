import React from 'react'
import { string } from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import HubspotFormContainer from './HubspotFormContainer'

const propTypes = {
  hubspotFormId: string,
}

const HubspotForm = ({ hubspotFormId, ...others }) => {
  const data = useStaticQuery(graphql`
    {
      allHubspotForms {
        edges {
          node {
            ...HubspotForm
          }
        }
      }
    }
  `)

  const {
    allHubspotForms: { edges: forms },
  } = data

  const hubspotForm = forms.find(({ node: form }) => form.id === hubspotFormId)
    ?.node

  if (!hubspotForm) return null

  const { name: formName, steps, submitText } = hubspotForm

  return (
    <HubspotFormContainer
      steps={steps}
      hubspotFormId={hubspotFormId}
      formName={formName}
      submitText={submitText}
      isGreenStone
      {...others}
    />
  )
}

HubspotForm.propTypes = propTypes

export default HubspotForm
