import React from 'react'

import { AppStore, PlayStore } from 'components/Icons'

const defaultProps = {
  alignment: 'center',
  as: 'a',
  variant: 'transparent',
}

export const getAppStoreButtonProps = (label, actionHref) => ({
  appStoreLink: {
    href: actionHref || 'https://apps.apple.com/br/app/creditas/id1270180256',
    content: (
      <>
        <AppStore />
        {label || 'App Store'}
      </>
    ),
    ...defaultProps,
  },
  playStoreLink: {
    href:
      actionHref ||
      'https://play.google.com/store/apps/details?id=br.com.creditas.mobile&hl=pt',
    icon: <PlayStore />,
    content: (
      <>
        <PlayStore />
        {label || 'Play Store'}
      </>
    ),
    ...defaultProps,
  },
})
