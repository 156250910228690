import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'

const Section = styled.section`
  overflow: hidden;
`

const Container = styled(CreditasContainer)(
  ({ theme }) => css`
    padding-top: ${selectTheme('spacingTop.16xl')({ theme })};
    padding-bottom: ${selectTheme('spacingBottom.16xl')({ theme })};

    ${media.up('5xl')} {
      padding-top: ${selectTheme('spacingTop.21xl')({ theme })};
      padding-bottom: ${selectTheme('spacingBottom.21xl')({ theme })};
    }
  `,
)

const gridWithImageVariantStyleLeft = css`
  ${media.up('3xl')} {
    grid-template-areas: '${'image '.repeat(4).trim()} ${'info '
      .repeat(4)
      .trim()}';
  }
  ${media.up('4xl')} {
    grid-template-areas: '${'image '.repeat(6).trim()} . ${'info '
      .repeat(6)
      .trim()}';
  }
`

const gridWithImageVariantStyleRight = css`
  ${media.up('3xl')} {
    grid-template-areas: '${'info '.repeat(4).trim()} ${'image '
      .repeat(4)
      .trim()}';
  }
  ${media.up('4xl')} {
    grid-template-areas: '${'info '.repeat(5).trim()} . ${'image '
      .repeat(6)
      .trim()}';
  }
`

const gridHorizontalAlignmentStyle = {
  left: gridWithImageVariantStyleLeft,
  right: gridWithImageVariantStyleRight,
}

const Grid = styled.div(
  ({ horizontalAlignment, theme }) => css`
    width: 100%;
    align-items: start;

    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    grid-template-areas:
      'info'
      'image';

    ${media.up('3xl')} {
      grid-gap: ${selectTheme('spacingX.9xl')({ theme })};
      grid-template-columns: repeat(8, 1fr);
    }

    ${media.up('4xl')} {
      grid-gap: ${selectTheme('spacingX.6xl')({ theme })};
      grid-template-columns: repeat(12, 1fr);
    }

    ${gridHorizontalAlignmentStyle[horizontalAlignment]}
  `,
)

export { Section, Container, Grid }
