import { Button as CreditasButton } from '@creditas/button'
import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

export const Title = styled(Typography)`
  margin-bottom: 16px;
`

export const Description = styled(Typography)`
  margin-bottom: 16px;
`

export const Button = styled(CreditasButton)`
  width: 100%;
  min-width: auto;
`

export const DownloadLink = styled.a`
  display: none;
`
