import { array, bool, number } from 'prop-types'

import WizardItem from './components/WizardItem'

import { Container } from './styles'

const propTypes = {
  currentStep: number,
  isSubmitting: bool,
  steps: array,
}

const Wizard = ({ steps: wizardItems = [], currentStep, isSubmitting }) => {
  return isSubmitting ? null : (
    <Container data-testid="wizard">
      {wizardItems?.map(({ title }, index) => {
        const stepNumber = index + 1
        return (
          <WizardItem
            key={`wizard-item-${stepNumber}`}
            stepNumber={stepNumber}
            showLabel={
              wizardItems?.length >= 5 ? currentStep === stepNumber : true
            }
            active={currentStep === stepNumber}
            checked={currentStep > stepNumber}
            name={title}
            isLast={stepNumber === wizardItems?.length}
            data-testid={`wizard-item-${stepNumber}`}
          />
        )
      })}
    </Container>
  )
}

Wizard.propTypes = propTypes

export default Wizard
