import React, { useCallback } from 'react'
import {
  array,
  bool,
  func,
  node,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'

import ChevronRightIcon from '@creditas/icons/dist/navigation/ChevronRight'
import { useSimulator } from '@creditas-lab/simulator'
import { scrollTo } from 'ui/utils/scrollTo'
import { triggerEvent } from 'utils/analytics'

import { getAppStoreButtonProps } from './AppStoreButton'

import {
  ActionLink,
  Button,
  FeaturedButton,
  StyledAppStoreButton,
} from './ButtonCta.style'

const VARIANTS = {
  primary: Button,
  secondary: Button,
  actionLink: ActionLink,
  featuredButton: FeaturedButton,
  appStoreLink: StyledAppStoreButton,
  playStoreLink: StyledAppStoreButton,
}

const propTypes = {
  actionHref: string,
  actionType: oneOf([
    'customAction',
    'link',
    'scrollTo',
    'showInnerForm',
    'simulator',
  ]),
  analyticEvent: oneOfType([
    bool,
    shape({
      eventAction: string,
      eventCategory: string,
      eventLabel: string,
      eventName: string,
    }),
  ]),
  children: oneOfType([node, array]),
  'data-testid': string,
  icon: func,
  innerForm: object,
  label: string,
  onClick: func,
  openInNewTab: bool,
  title: string,
  variant: oneOf([
    'actionLink',
    'appStoreLink',
    'featuredButton',
    'playStoreLink',
    'primary',
    'secondary',
  ]),
}

const defaultProps = {
  actionHref: '',
  actionType: 'link',
  'data-testid': 'button-cta',
  icon: ChevronRightIcon,
  innerForm: {},
  openInNewTab: false,
  variant: 'primary',
}

const ButtonCta = ({
  actionHref,
  actionType,
  analyticEvent,
  children: childrenForElement,
  'data-testid': dataTestId,
  icon,
  innerForm,
  label,
  onClick,
  openInNewTab,
  variant,
  ...other
}) => {
  const { openSimulator } = useSimulator()
  const Variant = VARIANTS[variant || defaultProps.variant]

  const elementProps = {
    target: openInNewTab ? '_blank' : '_self',
    href: actionType === 'link' ? actionHref : null,
    type: null,
    label,
    rel: openInNewTab ? 'noopener' : null,
    content: (
      <>
        {label}
        {childrenForElement}
      </>
    ),
    ...other,
  }

  const propsForButton = {
    variant,
    as: actionType == 'showInnerForm' ? 'button' : 'a',
  }

  const propsForFeaturedButton = {
    variant: 'primary',
    alignment: 'space-between',
    as: 'a',
  }

  const propsForActionLink = {
    icon,
  }

  const SPECIFIC_PROPS = {
    actionLink: propsForActionLink,
    featuredButton: propsForFeaturedButton,
    default: propsForButton,
  }

  Object.assign(elementProps, {
    ...(SPECIFIC_PROPS[variant] ||
      getAppStoreButtonProps(label, actionHref)[variant] ||
      SPECIFIC_PROPS.default),
  })

  const handleButtonOnClick = useCallback(
    event => {
      if (analyticEvent) {
        triggerEvent(analyticEvent)
      }

      if (actionType === 'simulator') {
        openSimulator()
      }

      if (actionType !== 'link' && actionType !== 'showInnerForm') {
        scrollTo(actionHref)
      }

      onClick?.(innerForm ? innerForm : event)
    },
    [analyticEvent, actionType, onClick, actionHref, innerForm, openSimulator],
  )

  return (
    <Variant
      data-testid={dataTestId}
      {...elementProps}
      onClick={handleButtonOnClick}
    >
      {elementProps.content}
    </Variant>
  )
}

ButtonCta.propTypes = propTypes
ButtonCta.defaultProps = defaultProps
ButtonCta.typeName = 'ContentfulComponentButtonCta'

export { ButtonCta }
