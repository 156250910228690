import { styled } from '@creditas/stylitas'

const Wrapper = styled.a`
  display: inline-block;
  height: 100%;
  text-decoration: none;
  width: fit-content;
`

export { Wrapper }
