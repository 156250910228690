import { styled } from '@creditas/stylitas'

import {
  webCardIcon,
  webCardIconLarge,
  webCardIconMedium,
  webCardIconOpacity,
  webCardIconRight,
  webCardIconSmall,
} from './webCard.style'

const WebCardIcon = styled.div`
  ${webCardIcon}
  ${webCardIconRight}
  ${webCardIconOpacity}
  ${webCardIconSmall}
  ${webCardIconMedium}
  ${webCardIconLarge}
`
export { WebCardIcon }
