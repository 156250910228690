import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

export const Container = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: ${selectTheme('spacingBottom.6xl')};
  overflow: hidden;
  width: calc(100vw - 20px);

  ${media.up('5xl')} {
    justify-content: flex-end;
    padding: 18px 0;
    margin-bottom: 32px;
    width: auto;
    overflow: visible;
  }
`
