import { useCallback } from 'react'
import { bool, number, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { ButtonLegacy } from '../../legacy/Button'

import { ArrowButton, SecondaryButton, TextButton } from './styles'

const propTypes = {
  children: string,
  currentStep: number,
  formName: string,
  isGreenStone: bool,
  secondary: bool,
  shouldDispatchEvents: bool,
  variant: string,
}

const defaultProps = {
  shouldDispatchEvents: false,
}

const Button = ({
  children,
  currentStep,
  formName,
  isGreenStone,
  secondary,
  shouldDispatchEvents,
  variant,
  ...others
}) => {
  const { triggerEvent } = useTriggerEvents()

  const onButtonSubmitClick = useCallback(() => {
    if (shouldDispatchEvents) {
      triggerEvent({
        label: `${formName} | ${children || ''}`,
        pageInteraction: false,
      })
    }
  }, [children, formName, shouldDispatchEvents, triggerEvent])

  if (!children) return null

  if (isGreenStone && currentStep === 1) {
    return (
      <ArrowButton
        type="button"
        size="large"
        width="fixed"
        onClick={onButtonSubmitClick}
        {...others}
      >
        {children}
      </ArrowButton>
    )
  } else if (isGreenStone && secondary) {
    return (
      <SecondaryButton type="button" onClick={onButtonSubmitClick} {...others}>
        {children}
      </SecondaryButton>
    )
  } else if (isGreenStone) {
    return (
      <TextButton
        type="button"
        size="large"
        width="fixed"
        onClick={onButtonSubmitClick}
        {...others}
      >
        {children}
      </TextButton>
    )
  }

  return (
    <ButtonLegacy
      variant={variant}
      type="button"
      onClick={onButtonSubmitClick}
      {...others}
    >
      {children}
    </ButtonLegacy>
  )
}

export default Button
Button.propTypes = propTypes
Button.propTypes = defaultProps
