/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { array, bool, func, oneOf, shape, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { FormProvider, useForm } from 'react-hook-form'
import { sendDataToHubspotAPI } from 'services/hubspot'
import { triggerEvent as triggerEventLegacy } from 'utils/analytics'

import FormStepper from 'components/FormElements/FormStepper'

import HubspotFormStepsContainer from './HubspotFormStepsContainer'
import convertFormDataToHubspotData from './utils/convertFormDataToHubspotData'

import { DownloadLink } from './HubspotForm.style'

const propTypes = {
  delayRedirect: bool,
  description: string,
  dispatchEvents: bool,
  downloadUrl: string,
  formName: string,
  formWithWebCardWrapper: bool,
  handleGoBackOnFirstStep: func,
  hubspotFormId: string.isRequired,
  isGreenStone: bool,
  postDownloadAnalyticEvent: shape({
    eventAction: string,
    eventCategory: string,
    eventLabel: string,
    eventName: string,
  }),
  redirectUrl: string,
  showDescription: bool,
  showGoBackOnFirstStep: bool,
  steps: array,
  submitText: string,
  template: oneOf(['Template01', 'Template02', 'Template03']),
  title: string,
}

const defaultProps = {
  steps: [],
  formName: '',
  submitText: 'Enviar',
  redirectUrl: '',
  downloadUrl: '',
  formWithWebCardWrapper: true,
  showDescription: false,
  showGoBackOnFirstStep: false,
  handleGoBackOnFirstStep: () => {},
  dispatchEvents: false,
  isGreenStone: false,
  delayRedirect: false,
  title: '',
}

const HubspotFormContainer = ({
  delayRedirect,
  description,
  dispatchEvents,
  downloadUrl,
  formName,
  formWithWebCardWrapper,
  handleGoBackOnFirstStep,
  hubspotFormId,
  isGreenStone,
  postDownloadAnalyticEvent,
  redirectUrl,
  showGoBackOnFirstStep,
  steps,
  submitText,
  template,
  title,
  ...others
}) => {
  const { triggerEvent } = useTriggerEvents()
  const [message, setMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const downloadLinkRef = useRef()
  const [pageUri, setPageUri] = useState('')
  const [pageName, setPageName] = useState('')

  useEffect(() => {
    setPageUri(window.location.href)
    setPageName(document.title)
  }, [])

  const methods = useForm({
    defaultValues: {},
    validateCriteriaMode: 'all',
    onSubmit: func,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })

  const {
    formState: { isSubmitting },
    handleSubmit,
    trigger,
  } = methods

  const currentStepFieldNames = useMemo(() => {
    return steps[currentStep - 1].fields.map(({ name }) => name)
  }, [currentStep, steps])

  const goToNextStep = async () => {
    const result = await trigger(currentStepFieldNames)
    if (!result) return
    setCurrentStep(prevStep => prevStep + 1)

    if (isGreenStone && dispatchEvents) {
      triggerEvent({
        label: `${formName} | Step - ${steps[currentStep - 1]?.title}`,
        pageInteraction: true,
      })
    }
  }

  const goToPrevStep = () => {
    setCurrentStep(prevStep => prevStep - 1)

    if (isGreenStone && dispatchEvents) {
      triggerEvent({
        label: `${formName} | Step - ${steps[currentStep - 1]?.title}`,
        pageInteraction: true,
      })
    }
  }

  const handlePostSubmit = success => {
    if (!success) return
    if (redirectUrl) {
      setTimeout(
        () => window.location.assign(redirectUrl),
        delayRedirect ? 5000 : 0,
      )
    } else if (downloadUrl) {
      downloadLinkRef.current.click()
    }
    if (postDownloadAnalyticEvent && !dispatchEvents) {
      triggerEventLegacy({ ...postDownloadAnalyticEvent, eventLabel: pageUri })
    }
  }

  const onSubmit = async data => {
    const result = await trigger()
    if (!result) return

    const formData = convertFormDataToHubspotData(
      steps,
      data,
      pageUri,
      pageName,
    )
    const response = await sendDataToHubspotAPI(formData, hubspotFormId)

    setIsSubmitted(true)
    setIsSuccess(response.success)
    setMessage(response.message)
    handlePostSubmit(response.success)

    if (dispatchEvents)
      triggerEvent({
        label: `${formName} | ${
          response.success ? 'success' : `error | ${response.message}`
        }`,
        pageInteraction: true,
      })
  }

  const trySubmitAgain = () => {
    setIsSubmitted(false)
    setMessage('')
  }

  const formRef = useRef(null)

  useEffect(() => {
    if (isSuccess) {
      const yOffset = -120
      const y =
        formRef?.current?.getBoundingClientRect().top +
        window?.pageYOffset +
        yOffset

      window?.scrollTo({ top: y, behavior: 'smooth' })
    }
  }, [isSuccess])

  return (
    <div ref={formRef}>
      <FormProvider {...methods}>
        <FormStepper
          dispatchEvents={dispatchEvents}
          title={title}
          formName={formName}
          description={description}
          trySubmitAgain={trySubmitAgain}
          onSubmit={handleSubmit(onSubmit)}
          onNextStep={goToNextStep}
          onPrevStep={goToPrevStep}
          currentStep={currentStep}
          currentStepName={steps[currentStep - 1].title}
          message={message}
          isSuccess={isSuccess}
          isSubmitting={isSubmitting}
          isSubmitted={isSubmitted}
          steps={steps}
          submitText={submitText}
          template={template}
          isGreenStone={isGreenStone}
          withWebCardWrapper={formWithWebCardWrapper}
          showGoBackOnFirstStep={showGoBackOnFirstStep}
          handleGoBackOnFirstStep={handleGoBackOnFirstStep}
          {...others}
        >
          <HubspotFormStepsContainer
            formName={formName}
            steps={steps}
            currentStep={currentStep}
            template={template}
            dispatchEvents={dispatchEvents}
            isGreenStone={isGreenStone}
          />
        </FormStepper>
      </FormProvider>

      {downloadUrl && (
        <DownloadLink
          ref={downloadLinkRef}
          target="_blank"
          href={downloadUrl}
          download={`${formName}-arquivo`}
          data-testid="download-link"
        />
      )}
    </div>
  )
}

HubspotFormContainer.propTypes = propTypes
HubspotFormContainer.defaultProps = defaultProps

export default HubspotFormContainer
