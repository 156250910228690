import React from 'react'
import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: string,
  color: string,
}

const defaultProps = {
  color: 'neutral.60',
}

const Description = ({ ...others }) => {
  return (
    <Typography
      color="neutral.60"
      variant="bodyMdRegular"
      data-testid="description"
      {...others}
    />
  )
}

Description.propTypes = propTypes
Description.defaultProps = defaultProps

export default Description
