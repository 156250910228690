import { bool, number, string } from 'prop-types'

import checkedIcon from '../../assets/check-icon.svg'

import { CheckIcon, Container, Indicator, Separator, StepName } from './styles'

const propTypes = {
  active: bool,
  checked: bool,
  isLast: bool,
  name: string,
  showLabel: bool,
  stepNumber: number,
}

const WizardItem = ({
  active,
  checked,
  isLast,
  name,
  showLabel,
  stepNumber,
}) => {
  const state = active ? 'active' : checked ? 'checked' : 'disabled'
  const indicatorContent = checked ? (
    <CheckIcon src={checkedIcon} />
  ) : (
    stepNumber
  )
  return (
    <Container className={state}>
      <Indicator className={state}>{indicatorContent}</Indicator>
      {showLabel && <StepName className={state}>{name}</StepName>}
      {active && !isLast && <Separator />}
    </Container>
  )
}

WizardItem.propTypes = propTypes
export default WizardItem
