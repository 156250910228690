import React from 'react'
import { func, object, string } from 'prop-types'

import { ButtonPrimaryTextOnly } from '@creditas-ui/button'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { StyledCta } from './styles'

const propTypes = {
  buttonCta: object,
  formName: string,
  label: string,
  onClick: func,
  productName: string,
}

const defaultProps = {
  buttonCta: {},
  onClick: () => {},
}

const ButtonCta = ({ buttonCta, formName, label, onClick, ...others }) => {
  const { triggerEvent } = useTriggerEvents()

  if (!buttonCta) return null

  const analyticEvent = {
    label: `${formName ? `${formName} | ` : ''}${label}`,
    pageInteraction: true,
  }

  const handleOnClick = () => {
    triggerEvent(analyticEvent)
    onClick()
  }

  return (
    <StyledCta
      element={ButtonPrimaryTextOnly}
      width="flexible"
      size="large"
      onClick={handleOnClick}
      isButtonElement
      {...buttonCta}
      {...others}
    >
      {label}
    </StyledCta>
  )
}

ButtonCta.propTypes = propTypes
ButtonCta.defaultProps = defaultProps

export default ButtonCta
