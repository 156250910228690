import React from 'react'
import { bool, string } from 'prop-types'

import { WebCardContent } from 'components/Card'

import { WebCard } from '../Form.style'

const withWebCard = Component => {
  const WithWebCard = props => {
    const { hasError, isGreenStone, template } = props

    return (
      <WebCard
        hasError={hasError}
        isGreenStone={isGreenStone}
        template={template}
      >
        <WebCardContent>
          <Component {...props} />
        </WebCardContent>
      </WebCard>
    )
  }

  WithWebCard.propTypes = {
    hasError: bool,
    isGreenStone: bool,
    template: string,
  }

  return WithWebCard
}

export default withWebCard
