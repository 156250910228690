import React from 'react'
import { array, bool, func, node, number, oneOf, string } from 'prop-types'

import ArrowRightIcon from '@creditas/icons/dist/navigation/ArrowRight'

import Button from './components/Button'
import { Container } from './components/Container/styles'
import PostSubmitMessage from './components/PostSubmitMessage'
import SubmitButton from './components/SubmitButton'
import Wizard from './components/Wizard'
import Form from './Form'
import withWebCard from './hocs/withWebCard'
import LegacyWizard from './legacy/Wizard'

const FormWithWebCard = withWebCard(Form)

const ButtonLabels = {
  next: 'Próximo',
  goBack: 'Voltar',
}

const propTypes = {
  children: node,
  currentStep: number,
  currentStepName: string,
  description: string,
  dispatchEvents: bool,
  formName: string,
  handleGoBackOnFirstStep: func,
  isGreenStone: bool,
  isSubmitted: bool,
  isSubmitting: bool,
  isSuccess: bool,
  message: string,
  onNextStep: func,
  onPrevStep: func,
  onSubmit: func,
  showDescription: bool,
  showGoBackOnFirstStep: bool,
  steps: array,
  submitText: string,
  submittingMessage: string,
  template: oneOf(['Template01', 'Template02']),
  title: string,
  trySubmitAgain: func,
  withWebCardWrapper: bool,
}

const defaultProps = {
  withWebCardWrapper: true,
  handleGoBackOnFirstStep: () => {},
  showDescription: false,
  showGoBackOnFirstStep: false,
  steps: [],
  dispatchEvents: false,
  isGreenStone: false,
}

const FormStepper = ({
  children,
  currentStep,
  currentStepName,
  description,
  dispatchEvents,
  formName,
  handleGoBackOnFirstStep,
  isGreenStone,
  isSubmitted,
  isSubmitting,
  isSuccess,
  message,
  onNextStep,
  onPrevStep,
  onSubmit,
  showDescription,
  showGoBackOnFirstStep,
  steps,
  submitText,
  submittingMessage,
  template,
  title,
  trySubmitAgain,
  withWebCardWrapper,
  ...others
}) => {
  const stepsLength = steps?.length
  const isLastStep = currentStep === stepsLength
  const showNextButton = !isLastStep
  const showPrevButton = showGoBackOnFirstStep || currentStep !== 1
  const showWizard = stepsLength > 1

  const FormElement = withWebCardWrapper ? FormWithWebCard : Form

  if (isGreenStone && isSubmitted) {
    return (
      <PostSubmitMessage
        formName={formName}
        isSuccess={isSuccess}
        successProps={{ successMessage: message }}
        errorProps={{ retryCtaAction: trySubmitAgain }}
      />
    )
  }

  return (
    <>
      {showWizard && isGreenStone && (
        <Wizard
          steps={steps}
          currentStep={currentStep}
          currentStepName={currentStepName}
          isSubmitting={isSubmitting}
        />
      )}
      <Container>
        <FormElement
          onSubmit={onSubmit}
          message={message}
          isSuccess={isSuccess}
          isSubmitting={isSubmitting}
          submittingMessage={submittingMessage}
          title={title}
          description={description}
          template={template}
          isGreenStone={isGreenStone}
          showDescription={showDescription}
          {...others}
        >
          {showWizard && !isGreenStone && (
            <LegacyWizard
              steps={Array.from(Array(stepsLength), (_, i) => i + 1)}
              currentStep={currentStep}
              currentStepName={currentStepName}
            />
          )}
          {children}

          <div className="stepperActions">
            {showNextButton && (
              <Button
                variant="primary"
                endIcon={<ArrowRightIcon />}
                type="button"
                onClick={onNextStep}
                isGreenStone={isGreenStone}
                currentStep={currentStep}
              >
                {ButtonLabels.next}
              </Button>
            )}

            <SubmitButton
              shouldDispatchEvents={!!dispatchEvents}
              formName={formName}
              shouldRender={!!isLastStep}
              submitText={submitText}
              isGreenStone={isGreenStone}
              isSubmitted={isSubmitted}
            />

            {showPrevButton && (
              <Button
                variant="secondary"
                type="button"
                onClick={
                  currentStep === 1 ? handleGoBackOnFirstStep : onPrevStep
                }
                isGreenStone={isGreenStone}
                secondary
              >
                {ButtonLabels.goBack}
              </Button>
            )}
          </div>
        </FormElement>
      </Container>
    </>
  )
}

FormStepper.propTypes = propTypes
FormStepper.defaultProps = defaultProps
export default FormStepper
