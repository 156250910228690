import { utilities } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'
import { css, styled } from '@creditas-ui/system'

export const WizardContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto 2rem;
  max-width: 60em;
  text-align: center;
  width: 100%;

  .wizard {
    width: 100%;
  }
`

export const WizardInfo = styled(Typography)(
  ({ theme }) => css`
    color: ${utilities.palette.color(theme, 'secondary05')};
    font-weight: 300;
    line-height: 24px;
    margin-top: -1em;
    text-align: center;
  `,
)
