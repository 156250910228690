import React, { useCallback, useState } from 'react'
import { array, func, string } from 'prop-types'

import { Radio as RadioInput } from '@creditas-ui/radio'

import { HelperText } from '../HelperText'
import { Legend } from '../Legend'

import { RadioGroup, Wrapper } from './styles'

const propTypes = {
  'data-testid': string,
  error: string,
  id: string,
  label: string,
  name: string,
  onChange: func,
  options: array,
  selectedOptions: array,
}

const defaultProps = {
  id: 'radio',
  options: [],
  selectedOptions: [],
  'data-testid': 'radio',
}

const Radio = React.forwardRef((props, ref) => {
  const {
    'data-testid': dataTestid,
    error,
    id,
    label,
    name,
    onChange,
    options,
    selectedOptions,
  } = props

  const radioOptions =
    options?.map(({ label: radioLabel, value }) => ({
      id: `${id}-${name}-${value}`,
      text: radioLabel,
      value,
    })) || []

  const [radioValue, setRadioValue] = useState(selectedOptions?.[0] || '')

  const handleChange = useCallback(
    e => {
      setRadioValue(e?.target?.value)
      onChange(e)
    },
    [onChange],
  )

  if (!radioOptions?.length) return null

  return (
    <Wrapper as="fieldset" data-testid={dataTestid}>
      <Legend>{label}</Legend>
      <RadioGroup>
        {radioOptions.map(radio => {
          return (
            <RadioInput
              checked={radioValue === radio.value}
              value={radio.value}
              label={radio.text}
              id={radio.id}
              key={radio.id}
              name={name}
              ref={ref}
              onChange={handleChange}
            />
          )
        })}
        <HelperText variant="bodyXsRegular" color="error.60">
          {error}
        </HelperText>
      </RadioGroup>
    </Wrapper>
  )
})

Radio.displayName = 'Radio'
Radio.propTypes = propTypes
Radio.defaultProps = defaultProps

export default Radio
