import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

export const Grid = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: repeat(4, 1fr);

  ${media.up('2xl')} {
    grid-column-gap: 36px;
    grid-template-columns: repeat(8, 1fr);
  }

  ${media.up('5xl')} {
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }
`
