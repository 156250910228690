import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

export const Title = styled(RichText)`
  margin-bottom: 16px;

  ${media.up('4xl')} {
    margin-bottom: 24px;
  }
`
