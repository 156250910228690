import React from 'react'
import { node } from 'prop-types'

import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

const WebCardLabelStyled = styled(Typography)`
  margin-top: 8px;
`

const propTypes = {
  children: node,
}

const WebCardLabel = ({ children }) => (
  <WebCardLabelStyled variant="supportForm">{children}</WebCardLabelStyled>
)

WebCardLabel.propTypes = propTypes

export { WebCardLabel }
