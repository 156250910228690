import React from 'react'
import { node, object, oneOf, string } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  bgColor: string,
  children: node,
  currentSectionRef: object,
  horizontalAlignment: oneOf(['left', 'right']),
}

const defaultProps = {
  horizontalAlignment: 'left',
}

const Wrapper = ({
  children,
  currentSectionRef,
  horizontalAlignment: horizontalAlignmentProp,
  ...others
}) => {
  const horizontalAlignment =
    horizontalAlignmentProp ?? defaultProps.horizontalAlignment
  return (
    <Section {...others} ref={currentSectionRef}>
      <Container>
        <Grid horizontalAlignment={horizontalAlignment}>{children}</Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes
Wrapper.defaultProps = defaultProps

export default Wrapper
