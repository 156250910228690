const getInputConfig = name => {
  const TYPES = [
    {
      mask: 'cpf',
      namePatterns: [/([-_]c|^c)p(f$|f[-_])/],
      pattern: /(\d{3}).(\d{3}).(\d{3})-(\d{2})/,
      message: 'Digite um CPF no formato 999.999.999-99',
      type: 'tel',
    },
    {
      mask: 'cnpj',
      namePatterns: [/([-_]c|^c)np(j$|j[-_])/],
      pattern: /^$|(\d{2})((\.\d{3}){2})\/(\d{4})-(\d{2})/,
      message: 'Digite um CNPJ no formato 99.999.999/9999-99',
      type: 'tel',
    },
    {
      mask: 'phone',
      namePatterns: [
        /([-_]p|^p)hon(e$|e[-_])/,
        /([-_]m|^m)obilephon(e$|e[-_])/,
        /([-_]t|^t)elefon(e$|e[-_])/,
        /([-_]c|^c)elula(r$|r[-_])/,
      ],
      pattern: /^$|\(\d{2}\) \d{4}-(\d{5}|\d{4})/,
      message: 'Digite um telefone no formato (99) 9999-9999',
      type: 'tel',
    },
    {
      mask: 'currency',
      namePatterns: [/([-_]c|^c)urrenc(y$|y[-_])/, /([-_]v|^v)alo(r$|r[-_])/],
      pattern: /^$|R\$ (\d.*)/i,
      message: 'Digite um valor no formato R$ 999,99',
      type: 'tel',
    },
    {
      mask: 'cep',
      namePatterns: [/([-_]c|^c)e(p$|p[-_])/],
      pattern: /(\d{5})-(\d{3})/,
      message: 'Digite um CEP no formato 00000-000',
      type: 'cep',
    },
    {
      mask: null,
      namePatterns: [/([-_]e|^e)mai(l$|l[-_])/],
      pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
      message: 'Digite um email no formato user@email.com',
      type: 'email',
    },
  ]

  const {
    mask = null,
    message = 'Campo inválido',
    pattern = null,
    type = 'text',
  } =
    TYPES.find(({ namePatterns }) =>
      namePatterns.some(namePattern => namePattern.test(name)),
    ) || {}

  return { mask, type, pattern, message }
}

export { getInputConfig }
