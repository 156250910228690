import React from 'react'
import { func, oneOfType, string } from 'prop-types'

import ButtonCta from '../components/ButtonCta'
import Description from '../components/Description'
import Icon from '../components/IconWithShape'
import InfoContainer from '../components/InfoContainer'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const propTypes = {
  errorMessage: string,
  errorTitle: string,
  formName: string,
  icon: string,
  retryCtaAction: func,
  retryCtaLabel: oneOfType([func, string]),
}

const defaultProps = {
  formName: '',
  errorTitle: 'Ops!<br/> Algo deu errado.',
  errorMessage:
    'Mas não se preocupe! Você ainda pode tentar novamente dentro de alguns instantes.',
  retryCtaLabel: 'Tentar novamente',
}

const ErrorMessage = ({
  errorMessage: customErrorMessage,
  errorTitle: customErrorTitle,
  formName,
  icon,
  retryCtaAction,
  retryCtaLabel: customRetryCtaLabel,
}) => {
  const errorMessage = customErrorMessage ?? defaultProps.errorMessage
  const errorTitle = customErrorTitle ?? defaultProps.errorTitle
  const retryCtaLabel = customRetryCtaLabel ?? defaultProps.retryCtaLabel

  return (
    <Wrapper>
      <Icon iconSrc={icon} />
      <InfoContainer>
        <Title>{errorTitle}</Title>
        <Description>{errorMessage}</Description>
        <ButtonCta
          formName={formName}
          label={retryCtaLabel}
          onClick={retryCtaAction}
        />
      </InfoContainer>
    </Wrapper>
  )
}

ErrorMessage.propTypes = propTypes
ErrorMessage.defaultProps = defaultProps
export default ErrorMessage
