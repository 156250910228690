import React from 'react'
import { bool, func, node, oneOf, string } from 'prop-types'

import {
  Description,
  FormErrorText,
  Spinner,
  SpinnerWrapper,
  StyledForm,
  Title,
} from './Form.style'

const propTypes = {
  children: node,
  description: string,
  hasError: bool,
  isGreenStone: bool,
  isSubmitting: bool,
  isSuccess: bool,
  message: string,
  onSubmit: func,
  showDescription: bool,
  submittingMessage: string,
  template: oneOf(['Template01', 'Template02', 'Template03']),
  title: string,
}

const defaultProps = {
  isSuccess: false,
  hasError: false,
  isSubmitting: false,
  showDescription: false,
  submittingMessage: 'Enviando formulário',
  message: '',
}

const Form = ({
  children,
  description,
  hasError,
  isGreenStone,
  isSubmitting,
  isSuccess,
  message,
  onSubmit,
  showDescription,
  submittingMessage,
  template,
  title,
  ...others
}) => {
  const showSpinner = isSubmitting || isSuccess
  const spinnerLoadingMessage = isSubmitting ? submittingMessage : ''
  const spinnerFinalMessage = isSubmitting ? submittingMessage : message || ''

  const showErrorMessage = !isSubmitting && !isSuccess && message

  const shouldHideForm = isSubmitting || isSuccess

  const shouldShowDescription =
    (template !== 'Template03' && description) ||
    (showDescription && description)

  return (
    <>
      <StyledForm
        data-testid="form"
        shouldHide={shouldHideForm}
        onSubmit={onSubmit}
        {...others}
      >
        {title && (
          <Title
            variant={template === 'Template02' ? 'h5' : 'h6'}
            as="p"
            template={template}
            className="formTitle"
            isGreenStone={isGreenStone}
          >
            {title}
          </Title>
        )}

        {shouldShowDescription && (
          <Description variant="support" isGreenStone={isGreenStone}>
            {description}
          </Description>
        )}

        {children}
      </StyledForm>
      {showSpinner && (
        <SpinnerWrapper>
          <Spinner
            data-testid="spinner"
            complete={isSuccess}
            loadingMessage={spinnerLoadingMessage}
            message={spinnerFinalMessage}
          />
        </SpinnerWrapper>
      )}
      {showErrorMessage && (
        <FormErrorText data-testid="error-text" variant="support" color="#fff">
          {message}
        </FormErrorText>
      )}
    </>
  )
}

Form.propTypes = propTypes
Form.defaultProps = defaultProps

export default Form
