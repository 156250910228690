import { styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { media } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
`

const Description = styled(Typography)`
  margin-left: 8px;
  max-width: 100%;
`

const Image = styled(LazyImage)`
  min-width: 32px;
  width: 32px;
  height: 32px;

  ${media.up('5xl')} {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  object-fit: contain !important;
`

export { Container, Description, Image }
