import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

const ImageElement = styled(LazyImage)`
  grid-area: image;
  object-fit: contain;
  width: 100%;
  margin: ${selectTheme('spacingY.5xl')};
  filter: drop-shadow(0px 28px 64px rgba(31, 45, 39, 0.16));

  ${media.up('3xl')} {
    margin: 0;
  }

  ${media.up('4xl')} {
    margin: 0;
  }
`

export { ImageElement }
