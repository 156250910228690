export default function stringToNumber(string) {
  let numberFromString

  const isCurrency = string.includes('R$')

  if (isCurrency) {
    let stringWithoutCurrencySymbol = string.replace(/R\$|-|\./g, '')
    stringWithoutCurrencySymbol = stringWithoutCurrencySymbol.replace(',', '.')

    numberFromString = Number(stringWithoutCurrencySymbol)
  } else {
    numberFromString = Number(string.replace(/[^[0-9]/gi, ''))
  }
  return numberFromString || 0
}
