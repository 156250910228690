import { forwardRef } from 'react'
import { bool, string } from 'prop-types'

import loadable from '@loadable/component'

const legacyFields = {
  text: loadable(() => import('components/FormElements/legacy/InputField')),
  textarea: loadable(() => import('components/FormElements/legacy/TextArea')),
  checkbox: loadable(() => import('components/FormElements/legacy/Checkbox')),
  radio: loadable(() => import('components/FormElements/legacy/Radio')),
  select: loadable(() => import('components/FormElements/legacy/Select')),
}

const fields = {
  text: loadable(() => import('components/FormElements/components/InputField')),
  number: loadable(() =>
    import('components/FormElements/components/InputField'),
  ),
  select: loadable(() => import('components/FormElements/components/Select')),
  textarea: loadable(() =>
    import('components/FormElements/components/Textarea'),
  ),
  checkbox: loadable(() =>
    import('components/FormElements/components/Checkbox'),
  ),
  booleancheckbox: loadable(() =>
    import('components/FormElements/components/Radio'),
  ),
  radio: loadable(() => import('components/FormElements/components/Radio')),
}

const propTypes = {
  fieldType: string,
  isGreenStone: bool,
}

const Fields = forwardRef((props, ref) => {
  const { fieldType, isGreenStone, ...others } = props

  const Field =
    isGreenStone && fields[fieldType]
      ? fields[fieldType]
      : legacyFields[fieldType]

  if (!Field) return null

  return <Field ref={ref} {...others} />
})

export default Fields
Fields.propTypes = propTypes
Fields.displayName = 'Field'
