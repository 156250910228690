import React from 'react'
import { node } from 'prop-types'

import { Container } from './styles'

const propTypes = {
  children: node,
}

const InfoContainer = ({ children, ...others }) => {
  return <Container {...others}>{children}</Container>
}

InfoContainer.propTypes = propTypes
export default InfoContainer
