import React from 'react'
import { node, object, oneOf, shape, string } from 'prop-types'

import { LazyImage } from 'components/LazyImage'

import { WebCardContent } from './WebCardContent'
import { WebCardIcon } from './WebCardIcon'

import { WebCardStyled } from './webCard.style'

const propTypes = {
  children: node,
  icon: node,
  image: shape({
    description: string,
    src: object,
  }),
  size: oneOf(['sm', 'md', 'lg']),
  variant: oneOf([
    'horizontal',
    'horizontal-opacity',
    'vertical',
    'vertical-no-background',
  ]),
}

const defaultProps = {
  variant: 'vertical',
  icon: null,
  image: null,
  size: 'sm',
}

const WebCard = ({ children, icon, image, size, variant, ...other }) => {
  return (
    <WebCardStyled variant={variant} {...other}>
      {icon && (
        <WebCardIcon variant={variant} size={size}>
          {icon}
        </WebCardIcon>
      )}
      {image && (
        <WebCardIcon data-testid="card-image" variant={variant} size={size}>
          <LazyImage alt={image?.description} aspectRatio={1} {...image} />
        </WebCardIcon>
      )}
      <WebCardContent variant={variant}>{children}</WebCardContent>
    </WebCardStyled>
  )
}

WebCard.propTypes = propTypes
WebCard.defaultProps = defaultProps

export { WebCard }
