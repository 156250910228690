/* eslint-disable react/jsx-no-literals */
import React, { useEffect, useMemo, useState } from 'react'
import { oneOf } from 'prop-types'

import ReclameAquiIcon from 'images/awards/reclame-aqui/reclame-aqui.svg'
import ReclameAquiIconMobile from 'images/awards/reclame-aqui/reclame-aqui-mobile.svg'
import ReclameAquiIconWhite from 'images/awards/reclame-aqui/reclame-aqui-white.svg'
import { setResizeHandle } from 'ui/utils/setResizeHandle'

import { Container, Description, Image } from './styles'

const propTypes = {
  variant: oneOf(['default', 'white', 'black']),
}

const defaultProps = {
  variant: 'default',
}

const BADGE_VARIANT = {
  default: ReclameAquiIcon,
  black: ReclameAquiIcon,
  white: ReclameAquiIconWhite,
  mobile: ReclameAquiIconMobile,
}

const ReclameAquiAward = ({ variant, ...others }) => {
  const [iconVariant, setIconVariant] = useState(variant)

  const handleResize = ({ innerWidth }) => {
    const isMobile = innerWidth < 960

    setIconVariant(isMobile ? 'mobile' : variant)
  }

  useEffect(() => {
    setResizeHandle(handleResize)
  })

  const reclameAquiBadgeSrc = useMemo(() => BADGE_VARIANT[iconVariant], [
    iconVariant,
  ])

  return (
    <Container {...others}>
      <Image src={reclameAquiBadgeSrc} alt="Reclame aqui" />

      <Description
        variant="bodyXsRegular"
        color={variant === 'black' ? 'neutral.90' : 'neutral.0'}
      >
        <strong>Ganhamos o Prêmio Reclame Aqui 2020 e 2021</strong>:<br />
        melhor empresa de empréstimo online.
      </Description>
    </Container>
  )
}

ReclameAquiAward.propTypes = propTypes
ReclameAquiAward.defaultProps = defaultProps

export default ReclameAquiAward
