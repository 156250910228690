import React from 'react'
import { node } from 'prop-types'

import { Container } from './styles'

const propTypes = {
  children: node,
}

const Wrapper = ({ children, ...others }) => {
  return <Container {...others}>{children}</Container>
}

Wrapper.propTypes = propTypes
export default Wrapper
