import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

const Container = styled.img`
  width: 80px;

  margin-bottom: ${selectTheme('spacingBottom.16xl')};

  object-fit: contain;
`

export { Container }
