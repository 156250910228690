import { ButtonPrimaryTextOnly } from '@creditas-ui/button'
import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

export const ButtonCreditasUi = styled(ButtonPrimaryTextOnly)`
  margin-top: 32px;

  ${media.up('5xl')} {
    margin-top: 40px;
  }
`
