import React from 'react'
import { array, bool, number, oneOf, string } from 'prop-types'

import FormStep from 'components/FormElements/FormStep'

import HubspotField from './HubspotField'

const propTypes = {
  currentStep: number,
  dispatchEvents: bool,
  formName: string,
  isGreenStone: bool,
  steps: array,
  template: oneOf(['Template01', 'Template02', 'Template03']),
}

const HubspotFormStepsContainer = ({
  currentStep,
  dispatchEvents,
  formName,
  isGreenStone,
  steps,
  template,
}) => {
  return (
    <>
      {steps.map(({ fields, title }, index) => (
        <FormStep
          key={`${formName}-step-${title}`}
          title={title}
          show={currentStep == index + 1}
        >
          {fields.map(field => (
            <HubspotField
              dispatchEvents={dispatchEvents}
              formName={formName}
              isGreenStone={isGreenStone}
              key={`${formName}-field-${field.name}`}
              template={template}
              {...field}
            />
          ))}
        </FormStep>
      ))}
    </>
  )
}

HubspotFormStepsContainer.propTypes = propTypes

export default HubspotFormStepsContainer
