import {
  ActionLink as CreditasActionLink,
  Button as CreditasButton,
} from '@creditas/button'
import { mq, styled } from '@creditas/stylitas'
import { Button as CreditasLabButton } from '@creditas-lab/button'
import { media } from '@creditas-lab/style-utilities'
import { fillMediaBase } from 'ui/utils/fillMediaBase'

const Button = styled(CreditasButton)`
  display: block;

  ${mq({
    maxWidth: fillMediaBase('100%', '288px'),
  })}

  text-align: center;
  text-decoration: none;
`

Button.displayName = 'Button'

const ActionLink = styled(CreditasActionLink)`
  padding: 0;
`

const FeaturedButton = styled(CreditasLabButton)``

const StyledAppStoreButton = styled(CreditasLabButton)`
  text-decoration: none;
  flex-basis: 50%;
  height: 48px;
  padding: 12px 20px;

  & + a {
    margin-left: 24px;
  }

  svg {
    margin-right: 8px;
  }

  ${media.md`
    flex-basis: auto;
  `}
`

export { Button, ActionLink, FeaturedButton, StyledAppStoreButton }
