import React from 'react'
import { object, oneOf, string } from 'prop-types'

import GlobalIcon, { sizeTypes as globalSizeTypes } from './GlobalIcon'
import ShapeIcon, {
  shapeAlignmentTypes,
  sizeTypes as shapeSizeTypes,
} from './ShapeIcon'

const propTypes = {
  __typename: string,
  image: object,
  shapeAlignment: oneOf([...shapeAlignmentTypes]),
  size: oneOf([...globalSizeTypes, ...shapeSizeTypes]),
  type: oneOf(['shape', 'global']),
}

const defaultProps = {
  type: 'global',
}

const CUSTOM_ICON_ELEMENT_VARIANTION = {
  shape: ShapeIcon,
  global: GlobalIcon,
}

const CustomIcon = ({ __typename, type, ...others }) => {
  const iconType = __typename
    ? __typename === 'ContentfulComponentShapeIcon'
      ? 'shape'
      : 'global'
    : type

  if (!iconType) return null
  const IconElement = CUSTOM_ICON_ELEMENT_VARIANTION[iconType]
  return <IconElement {...others} />
}

CustomIcon.propTypes = propTypes
CustomIcon.defaultProps = defaultProps
export default CustomIcon
