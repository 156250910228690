import { useCallback, useEffect, useState } from 'react'
import { bool, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { ButtonLegacy } from '../../legacy/Button'

import { ButtonCreditasUi } from './styles'

const propTypes = {
  formName: string,
  isGreenStone: bool,
  isSubmitted: bool,
  shouldDispatchEvents: bool,
  shouldRender: bool,
  submitText: string,
}

const defaultProps = {
  isSubmitted: false,
  shouldDispatchEvents: false,
  shouldRender: true,
  submitText: 'Enviar',
}

const SubmitButton = ({
  formName,
  isGreenStone,
  isSubmitted,
  shouldDispatchEvents,
  shouldRender,
  submitText,
  ...others
}) => {
  const { triggerEvent } = useTriggerEvents()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    let timeout

    if (isLoading) {
      timeout = setTimeout(() => {
        setLoading(false)
      }, 1000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isLoading])

  const onButtonSubmitClick = useCallback(() => {
    setLoading(true)

    if (shouldDispatchEvents) {
      triggerEvent({
        label: `${formName} | ${submitText || ''}`,
        pageInteraction: false,
      })
    }
  }, [formName, shouldDispatchEvents, submitText, triggerEvent])

  if (!shouldRender) return null

  const ButtonElement = isGreenStone ? ButtonCreditasUi : ButtonLegacy

  return (
    <ButtonElement
      variant="featuredButton"
      loading={Boolean(isLoading) ? isLoading : undefined}
      type="submit"
      width="fixed"
      size="large"
      onClick={onButtonSubmitClick}
      {...others}
    >
      {submitText || defaultProps.submitText}
    </ButtonElement>
  )
}

export default SubmitButton
SubmitButton.propTypes = propTypes
SubmitButton.propTypes = defaultProps
