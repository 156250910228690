import React from 'react'
import { node } from 'prop-types'

import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

const WebCardValueStyled = styled(Typography)`
  font-weight: 600;
`

const propTypes = {
  children: node,
}

const WebCardValue = ({ children }) => (
  <WebCardValueStyled variant="support">{children}</WebCardValueStyled>
)

WebCardValue.propTypes = propTypes

export { WebCardValue }
