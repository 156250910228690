import { css, styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

export const SIZE_VARIATIONS = {
  'x-small': 64,
  small: 80,
  medium: 120,
  large: 160,
}

const SHAPE_SIZE_VARIATIONS = {
  'x-small': css`
    width: 62.5%;
    height: 75%;
  `,
}

const SHAPE_ALIGNMENT_VARIATIONS = {
  'top-left': css`
    top: 4px;
    left: 6px;
  `,
  'top-right': css`
    top: 4px;
    right: 6px;
  `,
  'bottom-left': css`
    bottom: 4px;
    left: 6px;
  `,
  'bottom-right': css`
    bottom: 4px;
    right: 6px;
  `,
  'center-top': css`
    margin: 0 auto;
    top: 2px;
    left: 0;
    right: 0;
  `,
  'center-bottom': css`
    margin: 0 auto;
    bottom: 2px;
    left: 0;
    right: 0;
  `,
}

export const IconContainer = styled.div(
  ({ shapeAlignment, size, theme }) => css`
    position: relative;

    &::after {
      content: '';
      position: absolute;
      background: ${selectTheme('colors.primary.40')({ theme })};
      opacity: 0.25;
      border-radius: 8px;
      width: 77%;
      height: 85%;

      z-index: 0;

      ${SHAPE_SIZE_VARIATIONS[size]}
      ${SHAPE_ALIGNMENT_VARIATIONS[shapeAlignment]}
    }

    width: ${size ? `${SIZE_VARIATIONS[size]}px` : '100%'};
    height: ${size ? `${SIZE_VARIATIONS[size]}px` : '100%'};
  `,
)

export const Icon = styled(LazyImage)`
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: inherit !important;
  z-index: 1;
`
