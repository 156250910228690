import React from 'react'
import { object, oneOf } from 'prop-types'

import { Icon, IconContainer } from './styles'

export const sizeTypes = [
  'x-small',
  'small',
  'medium',
  'large',
  'x-large',
  '2x-large',
]

const propTypes = {
  image: object,
  size: oneOf(sizeTypes),
}

const defaultProps = {
  size: 'medium',
}

const GlobalIcon = ({ image, size, ...others }) => {
  return (
    <IconContainer data-testid="global-icon-container" size={size} {...others}>
      <Icon {...image} />
    </IconContainer>
  )
}

GlobalIcon.propTypes = propTypes
GlobalIcon.defaultProps = defaultProps

export default GlobalIcon
