import removeHtmlTagsFromString from 'utils/removeHtmlTagsFromString'

const HUBSPOT_API = 'https://api.hsforms.com/submissions/v3/integration/submit'
const HUBSPOT_PORTAL_ID = '4175809'

const sendDataToHubspotAPI = async (data, hubspotFormId) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }

  const response = await fetch(
    `${HUBSPOT_API}/${HUBSPOT_PORTAL_ID}/${hubspotFormId}`,
    options,
  )
  const responseJSON = await response.json()

  const message =
    responseJSON?.inlineMessage &&
    removeHtmlTagsFromString(responseJSON?.inlineMessage)

  return {
    success: response.ok || false,
    message: response.ok ? message : 'Ocorreu um erro. Tente novamente.',
  }
}

export { sendDataToHubspotAPI }
