import stringToNumber from 'utils/stringToNumber'

export default function convertFormDataToHubspotData(
  stepsFromHubspot,
  formData,
  pageUri = '',
  pageName = '',
) {
  const allFields = stepsFromHubspot
    .map(({ fields }) => fields)
    .reduce((old, current) => [...old, ...current], [])

  let fields = []

  Object.keys(formData).forEach(name => {
    const field = allFields.find(({ name: fieldName }) => fieldName === name)

    const fieldType = field.fieldType

    const fieldValue = formData[name]

    if (Array.isArray(fieldValue)) {
      const fieldsFromValueArray = fieldValue.map(value => {
        const valueForField =
          fieldType === 'number' ? stringToNumber(value) : value
        return {
          name,
          value: valueForField,
        }
      })

      fields = [...fields, ...fieldsFromValueArray]
    } else {
      const formattedFieldValue =
        fieldType === 'number' ? stringToNumber(fieldValue) : fieldValue

      fields.push({
        name,
        value: formattedFieldValue,
      })
    }
  })

  return {
    fields,
    context: {
      pageUri,
      pageName,
    },
  }
}
