import { Spinner as CreditasSpinner } from '@creditas/spinner'
import { css, styled, utilities } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

import { WebCard as WebsiteWebCard } from 'components/Card'

export const StyledForm = styled.form`
  ${props =>
    props.shouldHide &&
    css`
      display: none;
    `}
`

const borderNone = css`
  border: none;
  overflow: visible;
  padding: 0;

  &:hover {
    border: none;
    box-shadow: none;
  }
`

export const WebCard = styled(WebsiteWebCard)(
  ({ hasError, isGreenStone }) => css`
    margin: 0 auto;

    ${hasError && utilities.form.danger};

    ${isGreenStone && borderNone}
  `,
)

export const Spinner = styled(CreditasSpinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
`

export const FormErrorText = styled(Typography)`
  margin-top: 24px;
  text-align: center;
  background: red;
`

export const Title = styled(Typography)(
  ({ isGreenStone, template }) => css`
    margin-bottom: 16px;
    ${template === 'Template02' &&
    css`
      font-weight: 600;
    `}

    ${isGreenStone &&
    css`
      margin-bottom: 24px;
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.5px;
    `}
  `,
)

export const Description = styled(Typography)(
  ({ isGreenStone }) => css`
    margin-bottom: ${isGreenStone ? '40px' : '24px'};
  `,
)

export const SpinnerWrapper = styled.div`
  min-height: 240px;
`
