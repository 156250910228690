import { string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: string,
}

export const HelperText = ({ children, ...others }) => {
  if (!children) return false

  return (
    <Typography variant="bodyXsRegular" color="error.60" {...others}>
      {children}
    </Typography>
  )
}

HelperText.propTypes = propTypes
