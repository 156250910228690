import { RadioInputField as CreditasRadioInputField } from '@creditas/input/dist/RadioInputField'
import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

export const Wrapper = styled.div`
  position: relative;
`

export const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

export const RadioInputField = styled(CreditasRadioInputField)`
  fieldset {
    &:focus {
      outline: none;
    }

    > label {
      font-weight: 300;
      padding-right: 20px;
    }
  }
`

export const RadioGroup = styled.div`
  display: grid;
  row-gap: ${selectTheme('spacingY.6xl')};
  margin-bottom: ${selectTheme('spacingY.7xl')};

  ${media.up('5xl')} {
    row-gap: ${selectTheme('spacingY.8xl')};
  }
`
