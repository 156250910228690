import {
  ButtonPrimaryTextOnly,
  ButtonPrimaryWithArrow,
  ButtonSecondary,
} from '@creditas-ui/button'
import { css, styled } from '@creditas-ui/system'

export const buttonsStyle = css`
  margin-top: 40px;
`

export const TextButton = styled(ButtonPrimaryTextOnly)`
  ${buttonsStyle}
`

export const ArrowButton = styled(ButtonPrimaryWithArrow)`
  ${buttonsStyle}
`

export const SecondaryButton = styled(ButtonSecondary)`
  ${buttonsStyle};
  display: inline-block;
  margin-top: 32px;
  text-align: center;
  width: 100%;
`
