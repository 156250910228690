import { object, oneOf } from 'prop-types'

import { Icon, IconContainer } from './styles'

export const sizeTypes = ['x-small', 'small', 'medium', 'large']
export const shapeAlignmentTypes = [
  'top-left',
  'top-right',
  'bottom-left',
  'bottom-right',
  'center-bottom',
  'center-top',
]

const propTypes = {
  image: object,
  shapeAlignment: oneOf(shapeAlignmentTypes),
  size: oneOf(sizeTypes),
}

const defaultProps = {
  size: 'x-small',
  shapeAlignment: 'top-left',
}

const ShapeIcon = ({ image, shapeAlignment, size, ...others }) => {
  return (
    <IconContainer
      size={size}
      shapeAlignment={shapeAlignment}
      data-testid="shape-icon-container"
      {...others}
    >
      <Icon {...image} />
    </IconContainer>
  )
}

ShapeIcon.propTypes = propTypes
ShapeIcon.defaultProps = defaultProps

export default ShapeIcon
