import { Card } from '@creditas/card'
import { css, styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

const CARD_VARIATIONS = {
  'vertical-no-background': css`
    padding: 0px;
    border-radius: none;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: none;
      background: none;
      box-shadow: none;
      border: none;
    }
  `,
}

const CARD_AS_VARIATIONS = {
  button: css`
    cursor: pointer;
  `,
}

const WebCardStyled = styled(Card)(
  ({ as, variant }) => css`
    padding: 24px;

    ${variant && CARD_VARIATIONS[variant]}

    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-decoration: none;
    text-align: left;

    ${as && as === 'button' && CARD_AS_VARIATIONS[as]}
  `,
)

WebCardStyled.displayName = 'WebCardStyled'

const webCardIcon = () => css`
  margin-bottom: 1rem;
`

const webCardIconRight = ({ variant }) =>
  variant === 'horizontal' &&
  css`
    position: absolute;
    right: 24px;
    top: 24px;
    margin-bottom: 0;
  `

const webCardIconOpacity = ({ variant }) =>
  variant === 'horizontal-opacity' &&
  css`
    position: absolute;
    right: -24px;
    top: 50%;
    opacity: 0.2;
    margin: auto;
    transform: translateY(-50%);
  `

const webCardIconSmall = ({ size }) =>
  size === 'sm' &&
  css`
    width: 72px;
    height: 72px;
  `

const webCardIconMedium = ({ size }) =>
  size === 'md' &&
  css`
    width: 96px;
    height: 96px;
  `

const webCardIconLarge = ({ size }) =>
  size === 'lg' &&
  css`
    width: 144px;
    height: 144px;
  `

const webCardTitleWithRightIcon = ({ variant }) =>
  variant === 'horizontal' &&
  css`
    & > h6 {
      max-width: 160px;
    }
  `

const WebCardSubTitle = styled(Typography)`
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 15px;
`

const webCardContent = () => css`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export {
  webCardIcon,
  webCardIconRight,
  webCardIconOpacity,
  webCardIconSmall,
  webCardIconMedium,
  webCardIconLarge,
  webCardContent,
  webCardTitleWithRightIcon,
  WebCardSubTitle,
  WebCardStyled,
}
